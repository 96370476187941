.app {
  text-align: center;
  font-family: monospace;
  box-sizing: border-box;
}

.app-header {
  background: linear-gradient(to bottom, #00a281 0%, #00ddb8 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input {
  width: 100%;
  border: none;
  outline: none;
  padding: 15px;
  resize: none;
  box-sizing: border-box;
}

.window-wrapper {
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.2),
  15px 15px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid black;
}

.window-header-logo {
  width: 30px;
  margin-right: 5px;
}

.window-body {
  background: #CDE1D4;
}

.main-title {
  font-size: 21px;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
}

.window-header {
  background: linear-gradient(to bottom, #0f5ae0 0%, #1d4ee8 100%);
  padding: 5px;
  -webkit-box-shadow: inset 4px 4px 8px 2px rgba(7,193,253,0.49);
  -moz-box-shadow: inset 4px 4px 8px 2px rgba(7,193,253,0.49);
  box-shadow: inset 4px 4px 8px 2px rgba(7,193,253,0.49);
}

.window-sidebar {
  width: 200px;
  border: 1px solid black;
  border-right: none;
  border-left: none;
}
.window-right {
  width: 500px;
  border: 1px solid black;
  border-right: none;
}

.windows-background {
  width: 100%;
  height: 100%;
}

a.button {
  height: 18px;
  padding: 2px 8px;
  border: 1px solid #F3F3F3;
  -moz-box-shadow: 0 0 0 1px #707070;
  -webkit-box-shadow: 0 0 0 1px #707070;
  box-shadow: 0 0 0 1px #707070;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: -moz-linear-gradient(top, #F2F2F2 0%, #EBEBEB 50%, #DDDDDD 51%, #CFCFCF 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0,#F2F2F2), color-stop(0.5,#EBEBEB),color-stop(0.51,#DDDDDD),color-stop(1,#CFCFCF));
  background: linear-gradient(top, #F2F2F2 0%, #EBEBEB 50%, #DDDDDD 51%, #CFCFCF 100%);
  font: normal 12px sans-serif;
  color: black;
  text-decoration: none;
}
a.button:hover {
  border: 1px solid #ECF7FD;
  -moz-box-shadow: 0 0 0 1px #3C7FB1;
  -webkit-box-shadow: 0 0 0 1px #3C7FB1;
  box-shadow: 0 0 0 1px #3C7FB1;
  background: -moz-linear-gradient(top, #EAF6FD 0%, #D9F0FC 50%, #BEE6FD 51%, #A7D9F5 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0,#EAF6FD), color-stop(0.5,#D9F0FC),color-stop(0.51,#BEE6FD),color-stop(1,#A7D9F5));
  background: linear-gradient(top, #EAF6FD 0%, #D9F0FC 50%, #BEE6FD 51%, #A7D9F5 100%);
}
a.button:active {
  padding: 2px 7px 3px 9px;
  border: 1px solid #73A7C4;
  border-bottom: 0;
  -moz-box-shadow: 0 0 0 1px #2C628B;
  -webkit-box-shadow: 0 0 0 1px #2C628B;
  box-shadow: 0 0 0 1px #2C628B;
  background: -moz-linear-gradient(top, #E5F4FC 0%, #C4E5F6 50%, #98D1EF 51%, #68B3DB 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0,#E5F4FC), color-stop(0.5,#C4E5F6),color-stop(0.51,#98D1EF),color-stop(1,#68B3DB));
}
